/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import {fetch as fetchPolyfill} from "whatwg-fetch";
import {initI18} from "./src/logic/i18n";
window.fetch = fetchPolyfill;
initI18("en");
