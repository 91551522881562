import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enJson from "../lang/en.json";

export function initI18(lang = "en") {
  return i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: enJson,
      },
      lng: lang,
      // fallbackLng: "en",
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });
}
export default i18next;
